import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Flex,
  ConfigProvider,
  Skeleton,
  Empty
} from 'antd';

import CardTicket from '../components/cardTicket';
import ProfileUser from '../components/profileUser';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

const stylesContent = {
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '15px 30px 30px',
  boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.1)',
  margin: '5px 20px 20px 20px',
  height: '100vh'

}

// const data_t = [
//   {
//     message: 'ปัญหาการเข้าสู่ระบบ',
//     detailTicket: 'ทดสอบทดสอบทดสอบทดสอบทดสอบทดสอบ',
//     ticketNo: '240101001',
//     updateTime: '01/01/2024, 10:10',
//     statusTicket: 'Open'
//   },
//   {
//     message: 'ปัญหาการสร้างเอกสาร ',
//     detailTicket: 'เอกสารเอกสารเอกสารเอกสารเอกสารเอกสารเอกสาร',
//     ticketNo: '240101002',
//     updateTime: '01/01/2024, 00:15',
//     statusTicket: 'In progress'
//   }
// ]

const serveTicketEndpoint = process.env.REACT_APP_SERVE_TICKET_ENDPOINT;
const serveTicketApiKey = process.env.REACT_APP_SERVE_TICKET_API_KEY;
const serveTicketBusinessId = process.env.REACT_APP_SERVE_TICKET_BUSINESS_ID;

const FollowPage = () => {
  const [myTickets, setMyTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userUUID } = useParams();

  useEffect(() => {
    const getMyTickets = async () => {
      const apiUrl = `${serveTicketEndpoint}/public/ticket/ticketcustomer`;
      const queryParams = new URLSearchParams({
        limit_data: 20,
        start_data: 0,
        business_id: serveTicketBusinessId,
        customer_id: userUUID,
      }).toString();
      const urlWithParams = `${apiUrl}?${queryParams}`;
      console.log(urlWithParams);
      try {
        const response = await fetch(urlWithParams, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': serveTicketApiKey,
          },
        });
        const data = await response.json();
        const transformedData = data.data.ticket_array.map((item) => ({
          message: item.ticket_type,
          detailTicket: item.subject,
          ticketNo: item.ticket_no,
          updateTime: item.open_at,
          statusTicket: item.ticket_status,
        }));
        setMyTickets(transformedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      } finally {
        setLoading(false);
      }
    };
    getMyTickets();
  }, [userUUID]);


  return (
    <Flex justify='center' >
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          overflow: 'hidden',
          flexDirection: 'column',
          width: '600px',
          overflowY: 'hidden',
          height: '100vh',
          maxHeight: '700px',
        }}
      >
        <ProfileUser userUUID={userUUID} />
        <div style={stylesContent}>
          <Flex vertical>
            <div
              style={{
                display: 'flex',
                maxWidth: '100%',
                height: '50px',
                justifyContent: 'space-between',
              }}>
              <h4>ติดตามการแจ้งปัญหา</h4>
              <div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#182F59',
                    },
                  }}
                >
                  <Button style={{ width: '50px', marginTop: '15px' }} type='primary' shape='round' icon={<PlusOutlined />} href={`/${userUUID}/report-problem`} />
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#878787',
                    },
                  }}
                >
                  <Button style={{ width: '40px', margin: '0px 0px 0px 2px' }} type='primary' shape='round' icon={<ReloadOutlined />} href={`/${userUUID}/follow-up`} />
                </ConfigProvider>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                height: '480px',
                padding: '10px 10px 0px 0px',
                justifyContent: 'start',
                alignItems: 'stretch',
              }}
            >
              <Skeleton active loading={loading}>
                {myTickets.length > 0 ? (
                  myTickets.map((item) => (
                    <CardTicket key={item.ticketNo} {...item} />
                  ))
                ) : (
                  !loading && <Empty description="ไม่พบข้อมูลการแจ้งปัญหา" />
                )}
              </Skeleton>
              {/* <CardTicket />
            <Card title='Card' size='small'>
              <p>Card content</p>
            </Card> */}
            </div>
          </Flex>
        </div>
      </div>
    </Flex>
  );
};

export default FollowPage;