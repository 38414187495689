export const getSubjectlist = async () => {
    const serveTicketEndpoint = process.env.REACT_APP_SERVE_TICKET_ENDPOINT;
    const serveTicketBusinessId = process.env.REACT_APP_SERVE_TICKET_BUSINESS_ID;
    const serveTicketUserId = process.env.REACT_APP_SERVE_TICKET_USER_ID;
    const serveTicketApiKey = process.env.REACT_APP_SERVE_TICKET_API_KEY;

    const apiUrl = `${serveTicketEndpoint}/public/form/formticket`;
    const apiKey = serveTicketApiKey;

    const queryParams = new URLSearchParams({
        business_id: serveTicketBusinessId,
        user_id: serveTicketUserId,
    }).toString();

    const urlWithParams = `${apiUrl}?${queryParams}`;

    try {
        const response = await fetch(urlWithParams, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        // console.error('Error fetching data:', error);
        throw error;
    }
};
