import React from 'react';
import { useState, useEffect } from 'react'
import {
  Space,
  Form,
  Select,
  Input,
  Upload,
  Button,
  ConfigProvider,
  Flex,
  Checkbox
} from 'antd';

import {
  UploadOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;

const stylesContent = {
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '15px 20px 10px 20px',
  boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.15)',
  margin: '20px',

}

const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT

const FetchSubjectProblem = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendEndpoint}/tickets/subject-report-problem`);
        if (response.data && response.data.subjectList) {
          setOptions(response.data.subjectList);}
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <Flex>
      <Select
        placeholder="หัวข้อปัญหา"
        allowClear
        loading = {loading}
        size='large'
       >
        {options.map((subject, index) => (
          <Option key={index} value={subject}>
            {subject}
          </Option>
        ))}
      </Select>
    </Flex>
  )
}

const RequestContactPage = () => {
  const [fileList, setFileList] = useState([]);
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };
  return (
    <Flex justify='center'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          overflow: 'hidden',
          flexDirection: 'column',
          minWidth: 'fit-content',
          width: '600px',
        }}
      >
        <div style={stylesContent}>
          <div style={{ textAlign: 'center' }}>
            <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100 }} />
            <h3>แจ้งปัญหาการใช้งาน</h3>
          </div>
          <Form
            layout='vertical'
            initialValues={{ remember: true }}
            size='large'
          >
            <Form.Item
              label='หัวข้อ'
              name='subject_problem'
              rules={[{ required: true, message: 'กรุณาเลือกหัวข้อปัญหา' }]}
            >
              {/* <Select
                placeholder="หัวข้อปัญหา"
                allowClear
                options={[
                  {
                    value: '1',
                    label: 'ปัญหาการเข้าสู่ระบบ'
                  },
                  {
                    value: '2',
                    label: 'ปัญหาการลงนามเอกสาร'
                  },
                  {
                    value: '3',
                    label: 'ปัญหาการสร้างเอกสาร'
                  },
                  {
                    value: '4',
                    label: 'อื่นๆ'
                  },
                ]}
              /> */}
              <FetchSubjectProblem loading={{loadingP: true}}/>
            </Form.Item>
            <Form.Item
              label='รายละเอียดปัญหาที่พบ'
              name='details_problem'
              rules={[{ required: true, message: 'กรุณากรอกรายละเอียดปัญหาที่พบ' }]}
              tooltip={{
                title: 'โปรดระบุข้อมูลให้ครบถ้วน เช่น ชื่อ One mail ผู้ใช้งาน เลขที่เอกสาร',
                icon: <InfoCircleOutlined />,
              }}
            >
              <TextArea
                placeholder="รายละเอียดปัญหาที่พบ"
                autoSize={{
                  minRows: 4,
                  maxRows: 6,
                }}
              />
            </Form.Item>
            <Form.Item
              label='แนบไฟล์เพิ่มเติม'
            >
              <Space
                direction="vertical"
                style={{ width: '250px' }}
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Space>
            </Form.Item>
            <h3>ข้อมูลการติดต่อกลับ</h3>
            <Form.Item
              label='ชื่อ - นามสกุล'
              name='fullname'
              rules={[{ required: true, message: 'กรุณากรอกชื่อและนามสกุล!' }]}
            >
              <Input placeholder='ชื่อ - นามสกุล' />
            </Form.Item>
            <Form.Item
              label='เบอร์โทรศัพท์ ที่สามารถติดต่อได้'
              name='phone'
              rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์!' }]}
            >
              <Input placeholder='0987654321' />
            </Form.Item>
            <Form.Item
              label='อีเมล'
              name='email'
              rules={[{ required: true, type: 'email', message: 'กรุณากรอกอีเมลที่ถูกต้อง!' }]}
            >
              <Input placeholder='email@example.com' />
            </Form.Item>
            <Form.Item
              label='บริษัท/องค์กร'
              name='company'
              rules={[{ required: true, message: 'กรุณากรอกชื่อบริษัทหรือองค์กร!' }]}
            >
              <Input placeholder='บริษัท/องค์กร' />
            </Form.Item>
            <Form.Item
              name='privacyPolicy'
              valuePropName='checked'
              rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('คุณต้องยอมรับนโยบายความเป็นส่วนตัว') }]}
            >
              <Checkbox>นโยบายคุ้มครองข้อมูลส่วนบุคคล</Checkbox>
            </Form.Item>
            <Form.Item
              style={{ textAlign: 'end' }}
            >
              <Space align='start' >
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: '#182F59',
                    },
                  }}
                >
                  <Button htmlType='submit' style={{ width: '100px' }} type='primary' shape='round' >ส่ง</Button>
                </ConfigProvider>
                <ConfigProvider
                  theme={{
                    token: {
                      colorDefault: '#AEAEAE',
                    },
                  }}
                >
                  <Button htmlType='button' style={{ width: '100px' }} type='default' shape='round' >ย้อนกลับ</Button>
                </ConfigProvider>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Flex>
  );
};

export default RequestContactPage;