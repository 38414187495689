import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    ConfigProvider,
    Checkbox,
    Space,
    message,
    Modal
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

const stylesForm = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '15px 30px 20px 30px',
    // maxWidth: 'fit-content',
    minWidth: 'fit-content',
    width: '500px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    margin: '20px',
}

const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT

const SubmitButton = ({ form, children }) => {
    const [submittable, setSubmittable] = useState(false);

    const values = Form.useWatch([], form);
    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#182F59',
                },
            }}
        >
            <Button htmlType='submit' style={{ width: '100px' }} type='primary' shape='round' disabled={!submittable}>{children}</Button>
        </ConfigProvider>
    );
};

const RegisterPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const uid = searchParams.get("uid");
    const key = 'register';
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const openMessage = (type, content, duration) => {
        messageApi.open({
            key,
            type: type,
            content: content,
            duration: duration
        });
    };

    const onFinish = async (values) => {
        try {
            const updatedValues = {
                ...values,
                userId: uid
            };
            openMessage('loading', 'Loading...', 0)
            const response = await fetch(`${backendEndpoint}/tickets/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedValues),
            });

            if (response.ok) {
                const body = await response.json();
                const uuid = body.detail.uuid
                openMessage('success', 'Register Success!', 2)
                setTimeout(() => {
                    navigate(`/${uuid}/follow-up`);
                }, 1000);
            } else {
                throw new Error('Register failed');
            }
        } catch (error) {
            openMessage('error', error.message, 2)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showLoading = () => {
        setOpen(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const validatePhoneNumber = (_, value) => {
        const regex = /^\d{10}$/;
        if (!value || regex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error("กรุณากรอกเบอร์โทรศัพท์ที่ถูกต้อง!"));
    };

    return (
        <>
            <Modal
                title={<p>นโยบายคุ้มครองข้อมูลส่วนบุคคล</p>}
                loading={loading}
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#182F59',
                            },
                        }}
                    >
                        <Button onClick={() => setOpen(false)} style={{ width: '100px' }} type='primary' shape='round' >ปิด</Button>
                    </ConfigProvider>
                }
            >
                <p>
                    เรียน ผู้ใช้งานระบบ Digital Flow

                    วัตถุประสงค์ของข้อกำหนดการใช้งานระบบ Digital Flow  เพื่อเรียกใช้งาน ใบรับรองอิเล็กทรอนิกส์ (Digital Certificate) สำหรับนิติบุคคล ในการรับรองเอกสารอิเล็กทรอนิกส์ ที่ใช้บริการบนระบบ Digital Flow โดยอยู่ในข้อกำหนด (Terms and Conditions)

                    ข้อตกลงและเงื่อนไขการให้บริการระบบ Digital Flow และการใช้ใบรับรองอิเล็กทรอนิกส์ สำหรับนิติบุคคล (Digital Certificate)
                    ผู้ใช้งานจะต้องยินยอม ให้ระบบเรียกใช้งาน ใบรับรองอิเล็กทรอนิกส์  (Digital Certificate) สำหรับนิติบุคคล ในการรับรองเอกสารอิเล็กทรอนิกส์ ที่ใช้บริการบนระบบ Digital Flow โดยเป็นไปตามข้อกำหนด ข้อตกลงและเงื่อนไขการให้บริการระบบ (Terms and Conditions) และผู้ใช้งานจะต้องรับผิดชอบในการปฏิบัติตามกฎหมายและข้อกำหนดทั้งหมดที่เกี่ยวข้อง

                    โดยระบบจะมีการเพิ่มการขอความยินยอมในการเข้าถึงข้อมูลดังกล่าว โดยมีรายละเอียดดังต่อไปนี้
                    1. Username (ชื่อผู้ใช้งาน)
                    2. Access Token (โทเค็นการเข้าถึง)
                    3. Digital Certificate (ใบรับรองอิเล็กทรอนิกส์  สำหรับนิติบุคคล)

                    ท่านสามารถเลือกยินยอมหรือไม่ยินยอม ก่อนเข้าใช้งานระบบ Digital Flow ได้ หากเลือกไม่ยินยอม ระบบจะไม่ทำการจัดเก็บข้อมูลดังกล่าว และผู้ใช้บริการจะไม่สามารถเรียกใช้งานใบรับรองอิเล็กทรอนิกส์  (Digital Certificate) สำหรับนิติบุคคล ในการรับรองเอกสารอิเล็กทรอนิกส์ ที่ใช้บริการบนระบบ Digital Flow เนื่องจากระบบมีความจำเป็นที่ต้องการใช้ข้อมูลดังกล่าว

                    หมายเหตุ หากผู้ใช้งานมีคำถามหรือข้อสงสัยเกี่ยวกับการขอความยินยอมในการเข้าถึงข้อมูล โปรดติดต่อฝ่ายบริการลูกค้าของ Digital Flow : Call 02 257 7162
                </p>
            </Modal>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    overflow: 'hidden'
                }}
            >
                <div style={stylesForm}>
                    <div style={{ textAlign: 'center' }}>
                        <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100, background: '#fff' }} />
                        <h2>ลงทะเบียน</h2>
                        <h4>เพื่อให้เราสามารถบริการคุณ ได้รวดเร็วยิ่งขึ้น</h4>
                    </div>
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        size='large'
                    >
                        <Form.Item
                            label='ชื่อ - นามสกุล'
                            name='fullname'
                            rules={[{ required: true, message: 'กรุณากรอกชื่อและนามสกุล!' }]}
                        >
                            <Input placeholder='ชื่อ - นามสกุล' />
                        </Form.Item>
                        <Form.Item
                            label='เบอร์โทรศัพท์ ที่สามารถติดต่อได้'
                            name='phone'
                            rules={[{required: true, message: 'กรุณากรอกเบอร์โทรศัพท์!' }, { validator: validatePhoneNumber }]}
                        >
                            <Input placeholder='0987654321' />
                        </Form.Item>
                        <Form.Item
                            label='อีเมล'
                            name='email'
                            rules={[{ required: true, type: 'email', message: 'กรุณากรอกอีเมลที่ถูกต้อง!' }]}
                        >
                            <Input placeholder='email@example.com' />
                        </Form.Item>
                        <Form.Item
                            label='บริษัท/องค์กร'
                            name='company'
                            rules={[{ required: true, message: 'กรุณากรอกชื่อบริษัทหรือองค์กร!' }]}
                        >
                            <Input placeholder='บริษัท/องค์กร' />
                        </Form.Item>
                        <Form.Item
                            name='privacyPolicy'
                            valuePropName='checked'
                            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('คุณต้องยอมรับนโยบายความเป็นส่วนตัว') }]}
                        >
                            <Checkbox>นโยบายคุ้มครองข้อมูลส่วนบุคคล{<Button onClick={showLoading} size='small' type="link">(รายละเอียดเพิ่มเติม)</Button>}</Checkbox>
                        </Form.Item>
                        <Form.Item
                            style={{ textAlign: 'end' }}
                        >
                            <Space align='start'>

                                <SubmitButton form={form}>ลงทะเบียน</SubmitButton>
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorPrimary: '#AEAEAE',
                                        },
                                    }}
                                >
                                    <Button htmlType='button' style={{ width: '100px' }} type='default' shape='round' >ย้อนกลับ</Button>
                                </ConfigProvider>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default RegisterPage;