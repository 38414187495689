// import React from 'react';
// import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
// import {
//     Button,
//     Checkbox,
//     Col,
//     ColorPicker,
//     Form,
//     InputNumber,
//     Radio,
//     Rate,
//     Row,
//     Select,
//     Slider,
//     Space,
//     Switch,
//     Upload,
// } from 'antd';
// const { Option } = Select;
// const formItemLayout = {
//     labelCol: {
//         span: 6,
//     },
//     wrapperCol: {
//         span: 14,
//     },
// };
// const normFile = (e) => {
//     console.log('Upload event:', e);
//     if (Array.isArray(e)) {
//         return e;
//     }
//     return e?.fileList;
// };
// const onFinish = (values) => {
//     console.log('Received values of form: ', values);
// };
// const App = () => (
//     <Form
//         name="validate_other"
//         {...formItemLayout}
//         onFinish={onFinish}
//         initialValues={{
//             'input-number': 3,
//             'checkbox-group': ['A', 'B'],
//             rate: 3.5,
//             'color-picker': null,
//         }}
//         style={{
//             maxWidth: 600,
//         }}
//     >
//         <Form.Item label="Plain Text">
//             <span className="ant-form-text">China</span>
//         </Form.Item>
//         <Form.Item
//             name="select"
//             label="Select"
//             hasFeedback
//             rules={[
//                 {
//                     required: true,
//                     message: 'Please select your country!',
//                 },
//             ]}
//         >
//             <Select placeholder="Please select a country">
//                 <Option value="china">China</Option>
//                 <Option value="usa">U.S.A</Option>
//             </Select>
//         </Form.Item>

//         <Form.Item
//             name="select-multiple"
//             label="Select[multiple]"
//             rules={[
//                 {
//                     required: true,
//                     message: 'Please select your favourite colors!',
//                     type: 'array',
//                 },
//             ]}
//         >
//             <Select mode="multiple" placeholder="Please select favourite colors">
//                 <Option value="red">Red</Option>
//                 <Option value="green">Green</Option>
//                 <Option value="blue">Blue</Option>
//             </Select>
//         </Form.Item>

//         <Form.Item label="InputNumber">
//             <Form.Item name="input-number" noStyle>
//                 <InputNumber min={1} max={10} />
//             </Form.Item>
//             <span
//                 className="ant-form-text"
//                 style={{
//                     marginInlineStart: 8,
//                 }}
//             >
//                 machines
//             </span>
//         </Form.Item>

//         <Form.Item name="switch" label="Switch" valuePropName="checked">
//             <Switch />
//         </Form.Item>

//         <Form.Item name="slider" label="Slider">
//             <Slider
//                 marks={{
//                     0: 'A',
//                     20: 'B',
//                     40: 'C',
//                     60: 'D',
//                     80: 'E',
//                     100: 'F',
//                 }}
//             />
//         </Form.Item>

//         <Form.Item name="radio-group" label="Radio.Group">
//             <Radio.Group>
//                 <Radio value="a">item 1</Radio>
//                 <Radio value="b">item 2</Radio>
//                 <Radio value="c">item 3</Radio>
//             </Radio.Group>
//         </Form.Item>

//         <Form.Item
//             name="radio-button"
//             label="Radio.Button"
//             rules={[
//                 {
//                     required: true,
//                     message: 'Please pick an item!',
//                 },
//             ]}
//         >
//             <Radio.Group>
//                 <Radio.Button value="a">item 1</Radio.Button>
//                 <Radio.Button value="b">item 2</Radio.Button>
//                 <Radio.Button value="c">item 3</Radio.Button>
//             </Radio.Group>
//         </Form.Item>

//         <Form.Item name="checkbox-group" label="Checkbox.Group">
//             <Checkbox.Group>
//                 <Row>
//                     <Col span={8}>
//                         <Checkbox
//                             value="A"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                         >
//                             A
//                         </Checkbox>
//                     </Col>
//                     <Col span={8}>
//                         <Checkbox
//                             value="B"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                             disabled
//                         >
//                             B
//                         </Checkbox>
//                     </Col>
//                     <Col span={8}>
//                         <Checkbox
//                             value="C"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                         >
//                             C
//                         </Checkbox>
//                     </Col>
//                     <Col span={8}>
//                         <Checkbox
//                             value="D"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                         >
//                             D
//                         </Checkbox>
//                     </Col>
//                     <Col span={8}>
//                         <Checkbox
//                             value="E"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                         >
//                             E
//                         </Checkbox>
//                     </Col>
//                     <Col span={8}>
//                         <Checkbox
//                             value="F"
//                             style={{
//                                 lineHeight: '32px',
//                             }}
//                         >
//                             F
//                         </Checkbox>
//                     </Col>
//                 </Row>
//             </Checkbox.Group>
//         </Form.Item>

//         <Form.Item name="rate" label="Rate">
//             <Rate />
//         </Form.Item>

//         <Form.Item
//             name="upload"
//             label="Upload"
//             valuePropName="fileList"
//             getValueFromEvent={normFile}
//             extra="longgggggggggggggggggggggggggggggggggg"
//         >
//             <Upload name="logo" action="https://api-test.benzspace.com/api/v1/dwf/uploads" listType="picture">
//                 <Button icon={<UploadOutlined />}>Click to upload</Button>
//             </Upload>
//         </Form.Item>
//         <Form.Item label="Dragger">
//             <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
//                 <Upload.Dragger name="files" action="/upload.do">
//                     <p className="ant-upload-drag-icon">
//                         <InboxOutlined />
//                     </p>
//                     <p className="ant-upload-text">Click or drag file to this area to upload</p>
//                     <p className="ant-upload-hint">Support for a single or bulk upload.</p>
//                 </Upload.Dragger>
//             </Form.Item>
//         </Form.Item>
//         <Form.Item
//             name="color-picker"
//             label="ColorPicker"
//             rules={[
//                 {
//                     required: true,
//                     message: 'color is required!',
//                 },
//             ]}
//         >
//             <ColorPicker />
//         </Form.Item>

//         <Form.Item
//             wrapperCol={{
//                 span: 12,
//                 offset: 6,
//             }}
//         >
//             <Space>
//                 <Button type="primary" htmlType="submit">
//                     Submit
//                 </Button>
//                 <Button htmlType="reset">reset</Button>
//             </Space>
//         </Form.Item>
//     </Form>
// );
// export default App;
//----------------------------------------------------------------
// import React, { useState } from 'react';
// import { Upload, Button, message } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';

// const UploadComponent = () => {
//   const [fileList, setFileList] = useState([]);
//   const maxFileCount = 5;
//   const maxSizeInMB = 10;

//   const beforeUpload = (file) => {
//     const isSizeValid = file.size / 1024 / 1024 <= maxSizeInMB;

//     if (!isSizeValid) {
//       message.error(`${file.name} is larger than ${maxSizeInMB} MB.`);
//     }

//     return isSizeValid;
//   };

//   const handleChange = ({ fileList: newFileList }) => {
//     setFileList(newFileList);
//   };

//   const isDisabled = fileList.length >= maxFileCount;

//   return (
//     <div>
//       <Upload
//         fileList={fileList}
//         beforeUpload={beforeUpload}
//         onChange={handleChange}
//         onRemove={() => setFileList(fileList.filter((file) => file !== file))}
//         multiple
//       >
//         <Button 
//           icon={<UploadOutlined />} 
//           disabled={isDisabled}
//         >
//           {isDisabled ? 'Max Files Reached' : 'Upload'}
//         </Button>
//       </Upload>
//     </div>
//   );
// };

// export default UploadComponent;

//----------------------------------------------------------------
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';


const DevPage = () => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('file[]', file);
        });
        formData.append("description", "Test Upload");
        formData.append("user_id", "U123456789");
        console.log(formData.get('file[]'));
        setUploading(true);

        fetch('https://api-test.benzspace.com/api/v1/dwf/uploads', {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then(() => {
                setFileList([]);
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
     };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };
    return (
        <>
            <Upload {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
        </>
    );
};
export default DevPage;
//----------------------------------------------------------------
// import React, { useState } from 'react';

// const FormWithDynamicData = () => {
//   const [formData, setFormData] = useState({ name: '', email: '' });
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       // Step 3: Fetch additional data (e.g., an ID)
//       const response = await fetch(`https://api.example.com/get-id?email=${formData.email}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch ID');
//       }
//       const additionalData = await response.json(); // Assume response has an `id` field

//       // Step 4: Prepare FormData and include additional data
//       const dataToSend = new FormData();
//       dataToSend.append('name', formData.name);
//       dataToSend.append('email', formData.email);
//       dataToSend.append('id', additionalData.id); // Include the ID from the fetched data

//       // Step 5: Send FormData via POST
//       const postResponse = await fetch('https://api.example.com/submit-form', {
//         method: 'POST',
//         body: dataToSend,
//       });

//       if (!postResponse.ok) {
//         throw new Error('Failed to submit form');
//       }

//       alert('Form submitted successfully!');
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Something went wrong while submitting the form.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Name:</label>
//         <input
//           type="text"
//           name="name"
//           value={formData.name}
//           onChange={handleChange}
//           required
//         />
//       </div>
//       <div>
//         <label>Email:</label>
//         <input
//           type="email"
//           name="email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//         />
//       </div>
//       <button type="submit" disabled={loading}>
//         {loading ? 'Submitting...' : 'Submit'}
//       </button>
//     </form>
//   );
// };

// export default FormWithDynamicData;
