import React from 'react';
import { Badge, Flex } from 'antd';

const stylesCard = {
    borderRadius: '10px',
    border: '1px solid #182F59',
    borderLeft: '10px solid #182F59',
    boxShadow: '5px 8px 8px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#fff',
    padding: '0px 10px 0px 10px',
    margin: '0px 0px 8px 0px',
    maxWidth: '100%',
}

const StatusTicket = (props) => {
    const { status } = props;
    if (status === 'Open') {
        return <Badge count={'Open'} color='#0084E4' />
    }
    else if (status === 'In Progress' || status === 'Response To Customer') {
        return <Badge count={'In Progress'} color='#FAAD14' />
    }
    else if (status === 'Awaiting Customer' || status === 'Awaiting Vendor') {
        return <Badge count={'Await'} color='#17A2B8' />
    }
    else if (status === 'Resolved') {
        return <Badge count={'Resolved'} color='#00BD6E' />
    }
    else if (status === 'Closed') {
        return <Badge count={'Closed'} color='#8F8F8F' />
    }
    else if (status === 'Cancel') {
        return <Badge count={'Cancel'} color='#DC3545' />
    }
    else {
        return <Badge count={'Other'} color='#8F8F8F' />
    }
}

const CardTicket = (props) => {
    const { message, ticketNo, updateTime, statusTicket, detailTicket } = props;
    const dateString = new Date(updateTime).toLocaleString("en-GB");

    return (
        <div style={stylesCard}>
            <Flex justify='space-between' align='center'>
                <p>Ticket #{ticketNo}</p>
                <StatusTicket status={statusTicket} />
            </Flex>
            <Flex vertical style={{ borderTop: '1px solid #15748A' }}>
                <p style={{ margin: '10px 0px 5px' }}>{message}</p>
                <p
                    style={{
                        maxWidth: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '14px',
                        margin: '0px',
                        whiteSpace: 'nowrap',
                        height: '22px'
                    }}
                >{detailTicket}
                </p>
            </Flex>
            <Flex justify='end'>
                <p style={{ color: 'gray', fontSize: '14px', margin: '5px 0px' }}>Open at: {dateString}</p>
            </Flex>
        </div>
    );

}

export default CardTicket;

