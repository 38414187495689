import React, { useEffect, useState } from 'react';
import { Avatar, Flex } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const stylesProfile = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '0px 20px 0px 20px',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.1)',
    margin: '20px 20px 5px 20px',
}

const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

const ProfileUser = (props) => {
    const { userUUID } = props;
    const [profileName, setProfileName] = useState(null);
    const [pictureUrl, setPictureUrl] = useState(null);
    

    useEffect(() => {
        if (userUUID) {
            const fetchProfile = async () => {
                try {
                    const response = await fetch(`${backendEndpoint}/tickets/check-profile`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ uuid: userUUID }),
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch profile');
                    }
                    const data = await response.json();
                    setPictureUrl(data.detail.data.pictureUrl);
                    setProfileName(data.detail.data.name);
                } catch (error) {
                    console.error('Error fetching profile:', error);
                }
            };
            fetchProfile();
        }
    }, [userUUID]);

    return (
        <div style={stylesProfile}>
            <Flex horizontal={'horizontal'} align='center'>
                <Avatar size='middle' icon={<UserOutlined />} src={pictureUrl} />
                <p style={{ fontSize: '16px', marginLeft: '10px' }}>สวัสดี, คุณ {profileName} </p>
            </Flex>
        </div>
    )
}

export default ProfileUser;