import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex, ConfigProvider, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const stylesForm = {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '30px 30px 10px 30px',
    // maxWidth: 'fit-content',
    minWidth: 'fit-content',
    width: '300px',
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)',
    
}

const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT

const LoginPage = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, message) => {
        api[type]({
            message: title,
            description: message,
        });
    };
    const onFinish = async (values) => {
        try {
            const response = await fetch(`${backendEndpoint}/dwf/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const result = await response.json();
            if (result['access_token']) {
                openNotificationWithIcon('error', 'Success', 'Login success');
                localStorage.setItem('token', result['access_token']);
                navigate('/follow-up');
            }
            else {
                openNotificationWithIcon('error', 'Error', 'Incorrect username or password');
            }
            // console.log(result['access_token']);
        } catch (error) {
            console.error('Error:', error);
        }
        // console.log('Received values of form: ', values);
    };

    return (
        <>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100vh'
                }}>
                    <div style={stylesForm}>
                        <div style={{ textAlign: 'center' }}>
                            <img src='Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100, background: '#fff' }} />
                            <h2>Login to</h2>
                            <h3>Digital Flow Support</h3>
                        </div>
                        <Form
                            name="login"
                            initialValues={{
                                remember: true,
                            }}
                            style={{
                                maxWidth: 360,
                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username!',
                                    },
                                ]}

                            >
                                <Input prefix={<UserOutlined />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                            </Form.Item>
                            <Form.Item>
                                <Flex justify="space-between" align="center">
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox>Remember me</Checkbox>
                                    </Form.Item>
                                    <a href="/login">Forgot password</a>
                                </Flex>
                            </Form.Item>

                            <Form.Item>
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorPrimary: '#182F59',
                                        },
                                    }}
                                >
                                    <Button size={'large'} style={{ width: '100%' }} type='primary' shape='round' htmlType='submit'>Login</Button>
                                    or <a href="/register">Register now!</a>
                                </ConfigProvider>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default LoginPage;