import React from 'react';
import { Result } from 'antd';
const NotFoundPage = () => (
    <div style={styles.container}>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
        />
    </div>

);

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }}

export default NotFoundPage;