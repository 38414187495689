import RegisterPage from './pages/registerFormPage';
import FollowPage from './pages/followPage';
import RequestContactPage from './pages/requestContactPage';
import RequestPage from './pages/requestPage';
import LoginPage from './pages/loginPage';
import DevPage from './pages/devPage';
import LineLogin from './pages/lineLoginPage';
import LineCallback from './pages/lineCbPage';
import NotFoundPage from './pages/notFoundPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const fontFamily = { token: { fontFamily: 'Sarabun' } }

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/register"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <RegisterPage />
            </ConfigProvider>
          }
        />
        <Route
          path="/:userUUID/follow-up"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <FollowPage />
            </ConfigProvider>
          }
        />
        <Route
          path="/:userUUID/report-problem"
          element={
            <ConfigProvider
              theme={fontFamily}
            >
              <RequestPage />
            </ConfigProvider>
          }
        />
        <Route path="/" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <LoginPage />
          </ConfigProvider>} />
        <Route path="/login" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <LoginPage />
          </ConfigProvider>} />
        <Route path="/report-contact" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <RequestContactPage />
          </ConfigProvider>} />
        <Route path="/dev" element={
          <ConfigProvider
            theme={fontFamily}
          >
            <DevPage />
          </ConfigProvider>} />
        <Route path="/lineapp/login" element={<LineLogin />} />
        <Route path="/lineapp/callback" element={<LineCallback />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
