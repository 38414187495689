import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Space,
  Form,
  Input,
  Upload,
  Button,
  ConfigProvider,
  Flex,
  Select,
  message,
  Spin
} from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ProfileUser from '../components/profileUser';
import { getSubjectlist } from '../lib/getSubjectlist';

const { TextArea } = Input;

const stylesContent = {
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '15px 20px 10px 20px',
  boxShadow: '10px 5px 10px rgba(0, 0, 0, 0.1)',
  margin: '5px 20px 20px 20px',
}

const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
const serveTicketEndpoint = process.env.REACT_APP_SERVE_TICKET_ENDPOINT;
const serveTicketBusinessId = process.env.REACT_APP_SERVE_TICKET_BUSINESS_ID;
const serveTicketUserId = process.env.REACT_APP_SERVE_TICKET_USER_ID;
const serveTicketApiKey = process.env.REACT_APP_SERVE_TICKET_API_KEY;


const RequestPage = () => {
  const navigate = useNavigate();
  const { userUUID } = useParams();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'openticket';
  // const [uploading, setUploading] = useState(false);
  const maxFileCount = 5;
  const maxSizeInMB = 10;
  const isDisabled = fileList.length >= maxFileCount;

  const openMessage = (type, content, duration) => {
    messageApi.open({
      key,
      type: type,
      content: content,
      duration: duration
    });
  };

  const onFinish = async (values) => {
    const formData = new FormData();
    const data_value = values.subject_problem;
    const data_json = JSON.parse(data_value);

    //fix data
    formData.append('user_id', serveTicketUserId);
    formData.append('business_id', serveTicketBusinessId);

    //gat from webform
    formData.append('subject', data_json.detail);
    formData.append('description', values.details_problem);
    formData.append('form_ticket_id', data_json.id);
    fileList.forEach((file) => {
      formData.append('attachment', file);
    });

    //send to server or get from api for send to serve ticket
    formData.append('customer_id', userUUID);

    try {
      //get form ticket details
      const apiUrl = `${serveTicketEndpoint}/public/form/formticketdetails`;
      const apiKey = serveTicketApiKey;
      const queryParams = new URLSearchParams({
        business_id: serveTicketBusinessId,
        user_id: serveTicketUserId,
        form_ticket_id: data_json.id
      }).toString();
      const urlWithParams = `${apiUrl}?${queryParams}`;
      openMessage('loading', 'Open Ticket...', 0)
      const response = await fetch(urlWithParams, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        formData.append('form_ticket_details_id', data.data[0].form_ticket_details_id);
      } else {
        throw new Error('Failed to get ticket details');
      }

      //get form ticket details
      const getContact = await fetch(`${backendEndpoint}/tickets/get-contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuid: userUUID }),
      });
      if (getContact.ok) {
        const contact = await getContact.json();
        formData.append('notify_name', `${contact.detail.data.fullname} [${contact.detail.data.company}]`);
        formData.append('notify_phone', contact.detail.data.phone);
        formData.append('notify_email', contact.detail.data.email);

      } else {
        throw new Error('Failed to get contact');
      }

      // open ticket after get form ticket details and contact from api
      const openTicket = await fetch(`${serveTicketEndpoint}/public/ticket/form`, {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
        },
        body: formData,
      });
      if (openTicket.ok) {
        const body = await openTicket.json();
        const uuid = body.data.ticket.notify_position;
        const ticketNo = body.data.ticket.ticket_no;
        const ticketSubject = body.data.ticket.subject;

        const openAt = body.data.ticket.open_at;
        const dateStr = new Date(openAt);
        const unixTime = Math.floor(dateStr.getTime() / 1000);
        const pushNotifyOpen = await fetch(`${backendEndpoint}/line/linebot/push-notify-open`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uuid: userUUID, ticket_no: ticketNo, ticket_subject: ticketSubject, open_at: unixTime }),
        });
        if (pushNotifyOpen.ok) {
          openMessage('success', 'Open ticket success!', 2)
          setTimeout(() => {
            navigate(`/${uuid}/follow-up`);
          }, 1000);
        }
        else {
          throw new Error('Failed to open ticket');
        }
      } else {
        throw new Error('Failed to open ticket');
      }
    } catch (error) {
      openMessage('error', error.message, 2)
    }

  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file);
      const isSizeValid = file.size / 1024 / 1024 <= maxSizeInMB;
      // setFileList([...fileList, file]);
      if (!isSizeValid) {
        message.error(`${file.name} is larger than ${maxSizeInMB} MB.`);
        return false;
      } else {
        setFileList([...fileList, file]);
        return false;
      }
    },
    showUploadList: {
      extra: ({ size = 0 }) => (
        <span
          style={{
            color: '#cccccc',
          }}
        >
          ({(size / 1024 / 1024).toFixed(2)}MB)
        </span>
      ),
    },
    fileList,
  };

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const data = await getSubjectlist();
        const transformedData = data.data.map((item) => ({
          // value: {id: item.form_ticket_id,detail: item.form_ticket_name},
          value: JSON.stringify({ id: item.form_ticket_id, detail: item.form_ticket_name }),
          label: item.form_ticket_name.replace("- Digitalflow",""),
        }));
        setOptions(transformedData);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };
    loadOptions();
  }, []);


  return (
    <>{contextHolder}
      <Spin spinning={loading} size="large" fullscreen />
      <Flex justify='center'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            flexDirection: 'column',
            width: '600px',
          }}
        >
          <ProfileUser userUUID={userUUID} />
          <div style={stylesContent}>
            <div style={{ textAlign: 'center' }}>
              <img src='/Logo_DigitalFlow.png' alt='Logo' style={{ width: 100, height: 100 }} />
              <h3>แจ้งปัญหาการใช้งาน</h3>
            </div>
            <Form
              layout='vertical'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size='large'
            >
              <Form.Item
                label='หัวข้อ'
                name='subject_problem'
                rules={[{ required: true, message: 'กรุณาเลือกหัวข้อปัญหา' }]}
              >
                <Select
                  dropdownRender={(menu) => (
                    <div style={{ maxWidth: "90vw", overflow: "auto" }}>{menu}</div>
                  )}
                  options={options}
                  placeholder="หัวข้อปัญหา"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label='รายละเอียดปัญหาที่พบ'
                name='details_problem'
                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดปัญหาที่พบ' }]}
                tooltip={{
                  title: 'โปรดระบุข้อมูลให้ครบถ้วน เช่น ชื่อ One mail ผู้ใช้งาน เลขที่เอกสาร',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <TextArea
                  placeholder="รายละเอียดปัญหาที่พบ"
                  autoSize={{
                    minRows: 4,
                    maxRows: 6,
                  }}
                />
              </Form.Item>
              <Form.Item
                label='แนบไฟล์เพิ่มเติม'
                extra='สามารถแนบไฟล์ขนาดไม่เกิน 10 MB สูงสุด 5 ไฟล์'
              >
                <Space
                  direction="vertical"
                  style={{ width: '250px' }}
                >
                  <Upload {...props} multiple={true}>
                    <Button disabled={isDisabled} icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Space>
              </Form.Item>
              <Form.Item
                style={{ textAlign: 'end' }}
              >
                <Space align='start' >
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#182F59',
                      },
                    }}
                  >
                    <Button htmlType='submit' style={{ width: '100px' }} type='primary' shape='round' >ส่ง</Button>
                  </ConfigProvider>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#AEAEAE',
                      },
                    }}
                  >
                    <Button htmlType='button' style={{ width: '100px' }} type='default' shape='round' href={`/${userUUID}/follow-up`} >ย้อนกลับ</Button>
                  </ConfigProvider>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default RequestPage;