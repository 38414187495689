import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const LineCallback = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        // const friendShip = searchParams.get('friendship_status_changed');
        if (code && state) {
            const fetchAccessToken = async () => {
                try {
                    const response = await axios.post(
                        'https://api.line.me/oauth2/v2.1/token',
                        new URLSearchParams({
                            grant_type: 'authorization_code',
                            code,
                            redirect_uri: process.env.REACT_APP_LINE_REDIRECT_URI,
                            client_id: process.env.REACT_APP_LINE_CLIENT_ID,
                            client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET,
                        }),
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                    );

                    console.log('Access Token:', response.data.access_token);
                } catch (error) {
                    console.error('Error fetching access token:', error);
                }
            };

            fetchAccessToken();
        }
    }, [searchParams]);

    return <div>Processing Line login...</div>;
};

export default LineCallback;
